// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.liveView-content-data {
    margin: 0 20px;
    padding: 10px 0;
}

.liveView-content-flex {
    display: flex;
    justify-content: space-between;
}

.liveView-content-data-1-text {
    margin: 3px 0;
}

.liveView-content-country {
    margin-bottom: 5px;
}

.liveView-content-data-2 {
    width: 100%;
}

.liveView-content-data-1-number {
    font-size: 30px;
    font-weight: 200;
    margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/LiveView/Style.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".liveView-content-data {\n    margin: 0 20px;\n    padding: 10px 0;\n}\n\n.liveView-content-flex {\n    display: flex;\n    justify-content: space-between;\n}\n\n.liveView-content-data-1-text {\n    margin: 3px 0;\n}\n\n.liveView-content-country {\n    margin-bottom: 5px;\n}\n\n.liveView-content-data-2 {\n    width: 100%;\n}\n\n.liveView-content-data-1-number {\n    font-size: 30px;\n    font-weight: 200;\n    margin-top: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
