// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2:has(.highlight) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.highlight {
    border: 1px solid red;
    padding: 10px 20px;
    background: red;
    color: white;
    border-radius: 5px;
    text-align: center;
    margin: 0 auto;
    display: inline-block;
    font-size: 15px;
}

.highlight.--new{
    border: 1px solid var(--intastellarGold);
    background-color: var(--intastellarGold);
}

.highlight.--coming{
    border: 1px solid var(--IntastellarGrey);
    background-color: var(--IntastellarGrey);
}`, "",{"version":3,"sources":["webpack://./src/Components/Highlighter/Style.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,wCAAwC;IACxC,wCAAwC;AAC5C;;AAEA;IACI,wCAAwC;IACxC,wCAAwC;AAC5C","sourcesContent":["h2:has(.highlight) {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n}\n.highlight {\n    border: 1px solid red;\n    padding: 10px 20px;\n    background: red;\n    color: white;\n    border-radius: 5px;\n    text-align: center;\n    margin: 0 auto;\n    display: inline-block;\n    font-size: 15px;\n}\n\n.highlight.--new{\n    border: 1px solid var(--intastellarGold);\n    background-color: var(--intastellarGold);\n}\n\n.highlight.--coming{\n    border: 1px solid var(--IntastellarGrey);\n    background-color: var(--IntastellarGrey);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
