// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    padding: 30px 60px !important;
    font-size: 16px;
    text-align: center;
}

.links{
    color: #c09f53;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Style.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".footer{\n    padding: 30px 60px !important;\n    font-size: 16px;\n    text-align: center;\n}\n\n.links{\n    color: #c09f53;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
