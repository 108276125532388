// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successWindow{
    position: fixed;
    right: 0;
    top: 100px;
    background: rgb(58, 58, 58);
    width: auto;
    border-top: 2px solid green;
    transition: right .5s ease-in-out;
}

.successWindow-content{
    padding: 15px;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Components/SuccessWindow/Style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,UAAU;IACV,2BAA2B;IAC3B,WAAW;IACX,2BAA2B;IAC3B,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,WAAW;AACf","sourcesContent":[".successWindow{\n    position: fixed;\n    right: 0;\n    top: 100px;\n    background: rgb(58, 58, 58);\n    width: auto;\n    border-top: 2px solid green;\n    transition: right .5s ease-in-out;\n}\n\n.successWindow-content{\n    padding: 15px;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
