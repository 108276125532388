// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.platform{
    display: grid;
    place-content: center;
    place-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    grid-template-columns: 1fr;
    z-index: 20000;
    background-color: rgb(36, 36, 36);
}

.platform-select-logo{
    filter: invert(1);
    width: 35%;
    padding: 45px;
    margin-top: -105px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PlatformSelector/PlatformSelector.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,MAAM;IACN,0BAA0B;IAC1B,cAAc;IACd,iCAAiC;AACrC;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".platform{\n    display: grid;\n    place-content: center;\n    place-items: center;\n    height: 100vh;\n    position: fixed;\n    top: 0;\n    grid-template-columns: 1fr;\n    z-index: 20000;\n    background-color: rgb(36, 36, 36);\n}\n\n.platform-select-logo{\n    filter: invert(1);\n    width: 35%;\n    padding: 45px;\n    margin-top: -105px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
