// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-header {
    padding: 0;
    width: 100%;
}

.crawlerPage-logo {
    width: 150px;
    padding: 15px;
}

.main-content {
    width: 1200px;
    margin: auto;
}

.main-content-section-button {
    margin-top: 20px;
    color: #f8f9fa;
    background-color: #c09f53;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/LandingPage/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".main-header {\n    padding: 0;\n    width: 100%;\n}\n\n.crawlerPage-logo {\n    width: 150px;\n    padding: 15px;\n}\n\n.main-content {\n    width: 1200px;\n    margin: auto;\n}\n\n.main-content-section-button {\n    margin-top: 20px;\n    color: #f8f9fa;\n    background-color: #c09f53;\n    text-decoration: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
