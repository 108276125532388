// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cta{
    padding: 15px 30px;
    background-color: #1e90ff;
    border: none;
    margin-top: 10px;
    color: #fff;
    font-size: 1rem;
    border-radius: 5px;
}

.cta:disabled{
    background-color: #b3b3b3;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/AddUser/Style/Style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".cta{\n    padding: 15px 30px;\n    background-color: #1e90ff;\n    border: none;\n    margin-top: 10px;\n    color: #fff;\n    font-size: 1rem;\n    border-radius: 5px;\n}\n\n.cta:disabled{\n    background-color: #b3b3b3;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
