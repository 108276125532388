// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table{
    background-color: #5c5c5c;
    padding: 15px;
    border-radius: 10px;
    max-height: 500px;
    overflow: scroll;
    position: relative;
}

.table-header{
    font-size: 18px;
    border-bottom: 1px solid #fff;
    font-weight: bold;
}

.table-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 15px 0;
    gap: 10px;
    white-space: wrap;
}

.table-cell{
    color: #fff;
    padding: 0 5px;
    white-space: wrap;
    overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/Components/Tabel/Style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".table{\n    background-color: #5c5c5c;\n    padding: 15px;\n    border-radius: 10px;\n    max-height: 500px;\n    overflow: scroll;\n    position: relative;\n}\n\n.table-header{\n    font-size: 18px;\n    border-bottom: 1px solid #fff;\n    font-weight: bold;\n}\n\n.table-row{\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    padding: 15px 0;\n    gap: 10px;\n    white-space: wrap;\n}\n\n.table-cell{\n    color: #fff;\n    padding: 0 5px;\n    white-space: wrap;\n    overflow: scroll;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
