// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget{
    box-shadow: 0 0 3px 0 rgba(0,0,0,.22), inset -1px 1px 1px rgba(255, 255, 255, 0.5);
    background: radial-gradient(circle at top, rgb(101, 101, 101) -10%, rgb(48, 48, 48));
    border-radius: 10px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    min-height: 200px;
    margin: 20px 0px;
    color: rgb(197, 197, 197);
    text-align: center;
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    text-decoration: none;
}

.no-padding{
    padding: 0px;
}

/* .overviewTotal::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #c09f53;
}

.overviewDistribution::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ddd29b;
} */`, "",{"version":3,"sources":["webpack://./src/Components/widget/Widget.css"],"names":[],"mappings":"AAAA;IACI,kFAAkF;IAClF,oFAAoF;IACpF,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,0CAA0C;IAC1C,kDAAkD;IAClD,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB;;AAEA;;;;;;;;;;;;;;;;;;GAkBG","sourcesContent":[".widget{\n    box-shadow: 0 0 3px 0 rgba(0,0,0,.22), inset -1px 1px 1px rgba(255, 255, 255, 0.5);\n    background: radial-gradient(circle at top, rgb(101, 101, 101) -10%, rgb(48, 48, 48));\n    border-radius: 10px;\n    padding: 20px;\n    position: relative;\n    overflow: hidden;\n    min-height: 200px;\n    margin: 20px 0px;\n    color: rgb(197, 197, 197);\n    text-align: center;\n    backdrop-filter: saturate(180%) blur(20px);\n    -webkit-backdrop-filter: saturate(180%) blur(20px);\n    text-decoration: none;\n}\n\n.no-padding{\n    padding: 0px;\n}\n\n/* .overviewTotal::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 10px;\n    background-color: #c09f53;\n}\n\n.overviewDistribution::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 10px;\n    background-color: #ddd29b;\n} */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
