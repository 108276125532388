// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bigNumIsLoading{
    width: 100%;
    height: 44px;
    margin: 1em 0;
    background: linear-gradient(to right, rgba(131, 131, 131, 0), rgb(66, 66, 66), rgba(131, 131, 131, 0));
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 1ms;
}

.smallIsLoading{
    width: 70%;
    height: 34px;
    margin: 1em auto;
    background: linear-gradient(to right, rgba(131, 131, 131, 0), rgb(66, 66, 66), rgba(131, 131, 131, 0));
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 1ms;
}

@keyframes pulse {
    0%   {opacity: .5;}
    50%  {opacity: 1;}
    100% {opacity: .5;}
}  `, "",{"version":3,"sources":["webpack://./src/Components/widget/Loading.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sGAAsG;IACtG,qBAAqB;IACrB,sBAAsB;IACtB,mCAAmC;IACnC,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,sGAAsG;IACtG,qBAAqB;IACrB,sBAAsB;IACtB,mCAAmC;IACnC,oBAAoB;AACxB;;AAEA;IACI,MAAM,WAAW,CAAC;IAClB,MAAM,UAAU,CAAC;IACjB,MAAM,WAAW,CAAC;AACtB","sourcesContent":[".bigNumIsLoading{\n    width: 100%;\n    height: 44px;\n    margin: 1em 0;\n    background: linear-gradient(to right, rgba(131, 131, 131, 0), rgb(66, 66, 66), rgba(131, 131, 131, 0));\n    animation-name: pulse;\n    animation-duration: 2s;\n    animation-iteration-count: infinite;\n    animation-delay: 1ms;\n}\n\n.smallIsLoading{\n    width: 70%;\n    height: 34px;\n    margin: 1em auto;\n    background: linear-gradient(to right, rgba(131, 131, 131, 0), rgb(66, 66, 66), rgba(131, 131, 131, 0));\n    animation-name: pulse;\n    animation-duration: 2s;\n    animation-iteration-count: infinite;\n    animation-delay: 1ms;\n}\n\n@keyframes pulse {\n    0%   {opacity: .5;}\n    50%  {opacity: 1;}\n    100% {opacity: .5;}\n}  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
