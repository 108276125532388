// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 50px;
}

.stripe-price-table{
    width: 100%;
    margin-bottom: 50px;
}

.payment-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1400px;
    width: 100%;
    margin: auto;
    padding: 15px;
}
.logo{
    width: 5%;
    filter: invert(100%);
    float: left;
}

.footer a{
    color: #fff;
    text-decoration: none;
    display: inline-block;
    padding: 0 10px;
}

.footer a:hover{
    color: #E3DA37;
}

@media screen and (max-width: 600px){
    .logo{
        width: 12%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/StripePayment/Style/Stripe.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,iCAAiC;IACjC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;AACA;IACI,SAAS;IACT,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".content{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    box-sizing: border-box;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 300;\n    font-size: 16px;\n    line-height: 24px;\n    text-align: center;\n    margin-top: 50px;\n}\n\n.stripe-price-table{\n    width: 100%;\n    margin-bottom: 50px;\n}\n\n.payment-header{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    max-width: 1400px;\n    width: 100%;\n    margin: auto;\n    padding: 15px;\n}\n.logo{\n    width: 5%;\n    filter: invert(100%);\n    float: left;\n}\n\n.footer a{\n    color: #fff;\n    text-decoration: none;\n    display: inline-block;\n    padding: 0 10px;\n}\n\n.footer a:hover{\n    color: #E3DA37;\n}\n\n@media screen and (max-width: 600px){\n    .logo{\n        width: 12%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
