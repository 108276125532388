// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crawler-intro{
    text-align: center;
    line-height: .5em;
}
.crawler-form{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #8e8e8e;
    border-radius: 10px;
    padding: 15px;
}

.crawl-cta{
    padding: 12px 35px;
    border: none;
    border-radius: 5px;
    background-color: #dcbf00;
    width: max-content;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    margin-left: auto;
    flex-shrink: 0;
}

@media screen and (max-width: 768px){
    .crawler-form{
        flex-direction: column;
    }
    .crawl-cta{
        margin-left: 0;
        margin-top: 5px;
        width: 100%;
    }

    .crawler-intro{
        line-height: 1.5em;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/Components/Crawler/Style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,cAAc;QACd,eAAe;QACf,WAAW;IACf;;IAEA;QACI,kBAAkB;IACtB;;AAEJ","sourcesContent":[".crawler-intro{\n    text-align: center;\n    line-height: .5em;\n}\n.crawler-form{\n    display: flex;\n    gap: 20px;\n    justify-content: center;\n    align-items: center;\n    margin-top: 20px;\n    margin-bottom: 20px;\n    background-color: #8e8e8e;\n    border-radius: 10px;\n    padding: 15px;\n}\n\n.crawl-cta{\n    padding: 12px 35px;\n    border: none;\n    border-radius: 5px;\n    background-color: #dcbf00;\n    width: max-content;\n    font-size: 14px;\n    font-weight: bold;\n    text-transform: uppercase;\n    color: #fff;\n    margin-left: auto;\n    flex-shrink: 0;\n}\n\n@media screen and (max-width: 768px){\n    .crawler-form{\n        flex-direction: column;\n    }\n    .crawl-cta{\n        margin-left: 0;\n        margin-top: 5px;\n        width: 100%;\n    }\n\n    .crawler-intro{\n        line-height: 1.5em;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
