// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner{
    position: relative;
    width: 150px;
    height: 150px;
    margin: auto;
}

.spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 8px solid #9f9f9f;
    border-radius: 50%;
    border-top: 8px solid #fff;
    width: 100px;
    height: 100px;
    animation: spin .5s linear infinite;
}

@keyframes spin{
    0%{
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100%{
        transform: translate(-50%, -50%) rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/LoadingSpinner/Style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,yBAAyB;IACzB,kBAAkB;IAClB,0BAA0B;IAC1B,YAAY;IACZ,aAAa;IACb,mCAAmC;AACvC;;AAEA;IACI;QACI,6CAA6C;IACjD;IACA;QACI,+CAA+C;IACnD;AACJ","sourcesContent":[".loading-spinner{\n    position: relative;\n    width: 150px;\n    height: 150px;\n    margin: auto;\n}\n\n.spinner{\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    border: 8px solid #9f9f9f;\n    border-radius: 50%;\n    border-top: 8px solid #fff;\n    width: 100px;\n    height: 100px;\n    animation: spin .5s linear infinite;\n}\n\n@keyframes spin{\n    0%{\n        transform: translate(-50%, -50%) rotate(0deg);\n    }\n    100%{\n        transform: translate(-50%, -50%) rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
