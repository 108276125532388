// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyCta{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #befc4d;
    border-radius: 5px;
    margin-bottom: 15px;
    border: none;
    float: right;
}

.editable{
    text-decoration: underline;
    text-decoration-style: dotted;
    color: rgb(0, 76, 130);
}

.domain-list{
    padding: 0 15px;
    background-color: #f5f5f5;
    border-radius: 10px;
    color: #6b6b6b;
    padding-bottom: 15px;
    white-space: pre-line;
}

.editor{
    background-color: #d5d5d5;
    display: block;
    padding: 15px;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/DomainList/DomainList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;IAC7B,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".copyCta{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 20px;\n    background-color: #befc4d;\n    border-radius: 5px;\n    margin-bottom: 15px;\n    border: none;\n    float: right;\n}\n\n.editable{\n    text-decoration: underline;\n    text-decoration-style: dotted;\n    color: rgb(0, 76, 130);\n}\n\n.domain-list{\n    padding: 0 15px;\n    background-color: #f5f5f5;\n    border-radius: 10px;\n    color: #6b6b6b;\n    padding-bottom: 15px;\n    white-space: pre-line;\n}\n\n.editor{\n    background-color: #d5d5d5;\n    display: block;\n    padding: 15px;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
